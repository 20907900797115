import {
    get,
    post,
    put,
    remove
} from '@/api/config'



// == 订单 ==
export const TransactionQuery = query => get('v1/order/list' + query);
export const OrderQuery = parmas => post('v1/order/buy/list', parmas);
export const OrderDetail = query => get('v1/order/buy/details' + query);


// export const OrderCreate = params => post('v1/brand', params);

// export const OrderRemove = id => remove(`v1/brand/${id}`);

// export const OrderUpdate = params => put(`v1/brand/${params.id}`, params);

// export const OrderDetail = id => get(`v1/brand/query/${id}`);
