<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>交易记录</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item">
              <label>藏品名称：</label>
              <el-input
                placeholder="请输入藏品名称"
                v-model="query.NFTName"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>发送方：</label>
              <el-input
                placeholder="请输入发送方ID/手机号/区块链地址"
                v-model="query.fromLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item">
              <label>接收方：</label>
              <el-input
                placeholder="请输入接收方ID/手机号/区块链地址"
                v-model="query.toLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="x-con-item">
              <label>交易类型：</label>
              <el-select
                placeholder="请选择交易类型"
                v-model="query.transferType"
              >
                <el-option label="购买" value="1"></el-option>
                <el-option label="第三方" value="2"></el-option>
                <el-option label="转赠" value="3"></el-option>
                <el-option label="空投" value="4"></el-option>
                <el-option label="合成" value="5"></el-option>
                <el-option label="盲盒" value="6"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>发行时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>订单状态：</label>
              <el-select placeholder="请选择订单状态" v-model="query.status">
                <el-option label="执行中" value="1"></el-option>
                <el-option label="已成交" value="2"></el-option>
                <el-option label="失败" value="3"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>区块链状态：</label>
              <el-select
                placeholder="请选择区块链状态"
                v-model="query.chainStatus"
              >
                <el-option label="等待" value="1"></el-option>
                <el-option label="成交" value="2"></el-option>
                <el-option label="失败" value="3"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn mt25"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <!-- <el-button type="primary" size="small" class="fr" @click="goCreate"
            >品牌签约</el-button
          > -->
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="id" label="订单id"> </el-table-column>
            <el-table-column prop="NFTName" label="藏品名称" align="center">
            </el-table-column>
            <el-table-column
              prop="from"
              label="From"
              width="250"
            ></el-table-column>
            <el-table-column prop="to" label="To" align="center">
            </el-table-column>
            <el-table-column
              prop="createdAt"
              label="订单时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="transfer_type"
              label="交易类型"
              align="center"
            >
              <template slot-scope="scope">
                <span class="font-main" v-if="scope.row.transferType == 1"
                  >购买</span
                >

                <span class="font-main" v-else-if="scope.row.transferType == 2"
                  >第三方发放</span
                >

                <span class="font-main" v-else-if="scope.row.transferType == 3"
                  >转赠</span
                >

                <span class="font-main" v-else-if="scope.row.transferType == 4"
                  >空投</span
                >
                <span class="font-main" v-else-if="scope.row.transferType == 5"
                  >合成</span
                >
                <span class="font-main" v-else-if="scope.row.transferType == 6"
                  >盲盒</span
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="chainStatus"
              label="区块链状态"
              align="center"
            >
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.chainStatus == 1"
                  >等待</span
                >

                <span
                  class="font-success"
                  v-else-if="scope.row.chainStatus == 2"
                  >成功</span
                >
                <span class="font-danger" v-else-if="scope.row.chainStatus == 3"
                  >失败</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="updated_at" label="订单状态">
              <template slot-scope="scope">
                <span class="font-warning" v-if="scope.row.status == 1"
                  >执行中</span
                >

                <span class="font-danger" v-else-if="scope.row.status == 3"
                  >失败</span
                >

                <span class="font-success" v-else-if="scope.row.status == 2"
                  >已成交</span
                >
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="success"
                    @click="goDetail(scope.row)"
                    >查看</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TransactionQuery } from "@/api/module/order";

export default {
  name: "TransactionIndex",
  data() {
    return {
      showLoading: false,
      dialogshow: false,
      query: {
        transferType: "",
        fromLike: "",
        toLike: "",
        NFTName: "",
        status: "",
        chainStatus: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      TransactionQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },
    // 查看
    goDetail(row) {
      this.$message({
        type: "info",
        message: "功能正在开发中...",
        duration: 1300,
      });
    },
    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.fromLike) {
        query += `&fromLike=${this.query.fromLike}`;
      }
      if (this.query.toLike) {
        query += `&toLike=${this.query.toLike}`;
      }
      if (this.query.transferType) {
        query += `&transferType=${this.query.transferType}`;
      }
      if (this.query.NFTName) {
        query += `&NFTName=${this.query.NFTName}`;
      }
      if (this.query.status) {
        query += `&status=${this.query.status}`;
      }
      if (this.query.chainStatus) {
        query += `&chainStatus=${this.query.chainStatus}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }

      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        transferType: "",
        fromLike: "",
        toLike: "",
        NFTName: "",
        status: "",
        chainStatus: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



